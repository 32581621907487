export const CLIENT_LAST_ACCESSED = 'CLIENT_LAST_ACCESSED';
export const CLIENT_NAME = 'CLIENT_NAME';

export const clientIdLastAccessed = clientId => dispatch =>
  dispatch({
    type: CLIENT_LAST_ACCESSED,
    clientIdLastAccessed: clientId
  });

export const clientName = clientName => dispatch =>
  dispatch({
    type: CLIENT_NAME,
    clientName: clientName
  });
