import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Label from 'components/Label';

function NotificationCount({ notificationType }) {
  const notification = useSelector(state => state.notification);
  const notificationCount =
    notificationType === 'inbox'
      ? notification.inboxCount
      : notification.insightCount;

  if (notificationCount <= 0) {
    return null;
  }

  return (
    <Label color="#ff2d64" shape="rounded" style={{ marginRight: '6px' }}>
      {notificationCount}
    </Label>
  );
}

NotificationCount.propTypes = {
  notificationType: PropTypes.string
};

export default NotificationCount;
