export default {
    root: {
        marginTop: '-11px',
        marginBottom: '19px',
        fontSize: '13px'
    },
    contained: {
        marginLeft: 0,
        marginRight: 0
    }
};
