import api, { CancelToken } from './api';
import usePhoneHelper from '../utils/helper';

export const cancelToken = CancelToken;
export default class ClientService {
  static get = async (pageNumber, pageSize, name, apiToken) => {
    try {
      let response = await api.get('/api/v1/client', {
        params: {
          pageNumber,
          pageSize,
          name
        },
        cancelToken: apiToken
      });
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getClientDetail = async id => {
    try {
      let response = await api.get(`/api/v1/client/${id}/detail`);
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getHospitality = async (id, countryCode) => {
    try {
      let paramCountryCode = '';
      if (countryCode && countryCode !== undefined && countryCode !== '') {
        paramCountryCode = `?countryCode=${countryCode}`;
      }

      let response = await api.get(
        `/api/v1/client/${id}/dashboard/hospitality${paramCountryCode}`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getDashboardDestinationType = async (id, countryCode) => {
    try {
      let paramCountryCode = '';
      if (countryCode && countryCode !== undefined && countryCode !== '') {
        paramCountryCode = `?countryCode=${countryCode}`;
      }

      let response = await api.get(
        `/api/v1/client/${id}/dashboard/destination-type${paramCountryCode}`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getMeal = async (id, countryCode) => {
    try {
      let paramCountryCode = '';
      if (countryCode && countryCode !== undefined && countryCode !== '') {
        paramCountryCode = `?countryCode=${countryCode}`;
      }

      let response = await api.get(
        `/api/v1/client/${id}/dashboard/meals${paramCountryCode}`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getSeasonality = async (id, countryCode) => {
    try {
      let paramCountryCode = '';
      if (countryCode && countryCode !== undefined && countryCode !== '') {
        paramCountryCode = `?countryCode=${countryCode}`;
      }

      let response = await api.get(
        `/api/v1/client/${id}/dashboard/seasonality${paramCountryCode}`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getLandTranspor = async (id, countryCode) => {
    try {
      let paramCountryCode = '';
      if (countryCode && countryCode !== undefined && countryCode !== '') {
        paramCountryCode = `?countryCode=${countryCode}`;
      }

      let response = await api.get(
        `/api/v1/client/${id}/dashboard/land-transport${paramCountryCode}`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getAirTransport = async (id, countryCode) => {
    try {
      let paramCountryCode = '';
      if (countryCode && countryCode !== undefined && countryCode !== '') {
        paramCountryCode = `?countryCode=${countryCode}`;
      }

      let response = await api.get(
        `/api/v1/client/${id}/dashboard/air-transport${paramCountryCode}`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getLeisure = async (id, countryCode) => {
    try {
      let paramCountryCode = '';
      if (countryCode && countryCode !== undefined && countryCode !== '') {
        paramCountryCode = `?countryCode=${countryCode}`;
      }

      let response = await api.get(
        `/api/v1/client/${id}/dashboard/leisure${paramCountryCode}`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getRecentId = async () => {
    try {
      let response = await api.get('/api/v1/client/recent-id');
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getLastAccessedClient = async (pageNumber, pageSize) => {
    try {
      const response = await api.get('api/v1/client/recent', {
        params: {
          pageNumber,
          pageSize
        }
      });

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static save = async client => {
    try {
      let response = await api.post('/api/v1/client', client);
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static update = async client => {
    try {
      let response = await api.put('/api/v1/client', client);
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static savePhoto = async (id, photo) => {
    try {
      var data = new FormData();
      photo.forEach(file => {
        data.append('photo', file);
      });

      await api.put(`/api/v1/client/${id}/photo`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (ex) {
      return ex;
    }
  };

  static delete = async id => {
    try {
      let response = await api.delete(`/api/v1/client/${id}`);
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static favorite = async id => {
    try {
      let response = await api.put(`/api/v1/client/${id}/favorite`);
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static checkClientSocialMediaUpdating = async (
    clientId,
    socialMediaTypeId
  ) => {
    try {
      const response = await api.get(
        `/api/v1/client/${clientId}/social-media/${socialMediaTypeId}/updating`
      );

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static socialMedia = async (userName, socialMediaTypeId) => {
    try {
      let response = await api.post('/api/v1/socialmedia/userinfo', [
        {
          userName: userName,
          socialMediaTypeId: socialMediaTypeId
        }
      ]);

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data[0];
    } catch (ex) {
      return ex;
    }
  };

  static socialMediaSync = async (clientId, userName, socialMediaTypeId) => {
    try {
      let response = await api.post(
        `/api/v1/client/${clientId}/social-media/sync`,
        {
          userName: userName,
          socialMediaTypeId: socialMediaTypeId
        }
      );

      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static existsSocialMedia = async (clientId, userName) => {
    try {
      let response = await api.get(
        `/api/v1/client/${clientId}/social-media/${userName}/exists`,
        null
      );
      return response.data;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static getNoteTypes = async () => {
    try {
      let response = await api.get('/api/v1/client/note/type');
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getNotes = async (clientId, pageNumber, pageSize, clientNoteTypeId) => {
    try {
      let response = await api.get(`/api/v1/client/${clientId}/note`, {
        params: {
          pageNumber,
          pageSize,
          clientNoteTypeId
        }
      });
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getNoteDetail = async (clientId, id) => {
    try {
      let response = await api.get(
        `/api/v1/client/${clientId}/note/${id}/detail`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getLastNoteCovidInsertedId = async (clientId) => {
    try {
      let response = await api.get(
        `/api/v1/client/${clientId}/note/covid/recent-id`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getPassports = async (clientId, pageNumber, pageSize) => {
    try {
      let response = await api.get(`/api/v1/client/${clientId}/passport`, {
        params: {
          pageNumber,
          pageSize
        }
      });
      if (response.data === null) {
        throw new Error(response);
      }
      return response;
    } catch (ex) {
      return ex;
    }
  };

  static saveNote = async (clientId, clientNoteCriteria) => {
    try {
      let response = await api.post(
        `/api/v1/client/${clientId}/note`,
        clientNoteCriteria
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static saveCovidNote = async (clientId, newNote) => {
    try {
      let response = await api.post(
        `/api/v1/client/${clientId}/note/covid`,
        newNote
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static createPassport = async (clientId, passport) => {
    try {
      let response = await api.post(
        `/api/v1/client/${clientId}/passport`,
        passport
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static updateNote = async (clientId, clientNoteCriteria) => {
    try {
      let response = await api.put(
        `/api/v1/client/${clientId}/note`,
        clientNoteCriteria
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static updateCovidNotes = async (clientId, lstClientNoteCriteria) => {
    try {
      let response = await api.put(
        `/api/v1/client/${clientId}/note/covid`,
        lstClientNoteCriteria
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static updatePassport = async (clientId, passport) => {
    try {
      let response = await api.put(
        `/api/v1/client/${clientId}/passport`,
        passport
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static deleteNote = async (clientId, id) => {
    try {
      let response = await api.delete(`/api/v1/client/${clientId}/note/${id}`);
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static deletePassport = async (clientId, passportId) => {
    try {
      let response = await api.delete(`/api/v1/client/${clientId}/passport/${passportId}`);
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (error) {
      return error;
    }
  }

  static deleteVisa = async (clientId, visaId) => {
    try {
      let response = await api.delete(`/api/v1/client/${clientId}/passport/visa/${visaId}`);
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (error) {
      return error;
    }
  }

  static mapping = client => {
    let phoneHelper = usePhoneHelper();

    //tratamento emails
    for (let i = 0; i < client.emails.length; i++) {
      const element = client.emails[i];
      if (element.address === '' || element.address === undefined) {
        client.emails.splice(i, 1);
      }
    }

    let obj = {
      id: client.id,
      firstName: client.firstName,
      lastName: client.lastName,
      gender: client.gender,
      birthDate:
        client.birthDate === '0001-01-01T00:00:00' || client.birthDate === ''
          ? ''
          : client.birthDate,
      photoLink: client.photoLink,
      emails: client.emails,
      emailsDelete:
        client.emailsDelete && client.emailsDelete.map(email => email.id),
      phones: client.phones
        ?.filter(a => !!a.numberFull)
        ?.map(phone => {
          return {
            ...phone,
            ...phoneHelper.parse(phone.numberFull)
          };
        }),
      phonesDelete:
        client.phonesDelete && client.phonesDelete.map(phone => phone.id),
      addresses: client.addresses?.filter(
        a => a.placeGatewayId && a.placeGatewayId !== ''
      ),
      addressesDelete: client.addresses
        ?.filter(
          a => a.id > 0 && (!a.placeGatewayId || a.placeGatewayId === '')
        )
        ?.map(address => address.id),
      document: client.documents[0],
      socialMedia: client.socialMedia
    };
    return obj;
  };
}
