import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default function usePhoneHelper() {
  let unmaskPhone = phoneMasked => {
    return '+' + phoneMasked.replace(/[^\d]/g, '');
  };

  let typePhone = phone => {
    if (!phone || phone.trim() === '') return null;
    const phoneNumber = parsePhoneNumberFromString(unmaskPhone(phone), 'BR');
    if (!phoneNumber?.isValid()) return null;
    let countryCode = phoneNumber.countryCallingCode.toString();
    let nationalNumber = phoneNumber.nationalNumber.toString();
    let areaCodeLength = countryCode === '55' ? 2 : 3;
    let areaCode = nationalNumber.substr(0, areaCodeLength);
    let number = nationalNumber.substr(areaCodeLength);
    return {
      countryCode: countryCode,
      areaCode: areaCode,
      number: number
    };
  };

  let stringifyPhone = phone => {
    if (!phone || !phone.number) return '';
    if (!phone.countryCode) {
      phone.countryCode = 55;
    }
    if (!phone.areaCode) {
      phone.areaCode = '0';
    }
    let phoneNumber = parsePhoneNumberFromString(
      `+${`${phone.countryCode}${phone.areaCode}${phone.number}`.replace(
        '+',
        ''
      )}`,
      'BR'
    );
    if (!phoneNumber?.isValid()) return '';
    return phoneNumber.formatInternational();
  };

  return {
    parse: phone => typePhone(phone),
    stringify: phone => stringifyPhone(phone),
    textLimiter: (text, length = 95) => {
      if (text.length > length) return ''.concat(text.substr(0, length), '...');
      return text;
    },
    isValidPhone: value => {
      const phoneNumber = parsePhoneNumberFromString(value, 'BR');
      return !!phoneNumber?.isValid();
    }
  };
}
