import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, colors } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { clientIdLastAccessed, notificationInbox, notificationInsight } from 'actions';
import Link from '@material-ui/core/Link';
import NotificationService from '../services/notification';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    fontSize: '10px',
    height: 20,
    minWidth: 20,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5, 1)
  },
  rounded: {
    borderRadius: 10,
    padding: theme.spacing(0.5)
  }
}));

const Label = ({
  className,
  variant,
  color,
  shape,
  clientId,
  children,
  style,
  enableClick,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const rootClassName = clsx(
    {
      [classes.root]: true,
      [classes.rounded]: shape === 'rounded'
    },
    className
  );

  const finalStyle = { ...style };

  if (variant === 'contained') {
    finalStyle.backgroundColor = color;
    finalStyle.color = '#FFF';
  } else {
    finalStyle.border = `1px solid ${color}`;
    finalStyle.color = color;
  }

  const handleRedirect = () => {
    dispatch(clientIdLastAccessed(clientId));
    dispatchInboxCount();
    dispatchInsightCount();
    history.push('/client/insights');
  };

  const dispatchInboxCount = async () => {
    const notificationInboxCount = await NotificationService.inboxCount();
    dispatch(notificationInbox(notificationInboxCount));
  };

  const dispatchInsightCount = async () => {
    const notificationInsightCount = await NotificationService.insightCount(
      clientId
    );
    dispatch(notificationInsight(notificationInsightCount));
  };

  return (
    <Typography
      {...rest}
      className={rootClassName}
      style={finalStyle}
      variant="overline">
      {enableClick ? (
        <Link
          className={classes.tableLinkCell}
          component="button"
          onClick={handleRedirect}
          variant="body2"
          underline="none"
          style={{
            color: '#ffffff'
          }}>
          {children}
        </Link>
      ) : (
        children
      )}
    </Typography>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  clientId: PropTypes.number,
  color: PropTypes.string,
  enableClick: PropTypes.bool,
  shape: PropTypes.oneOf(['square', 'rounded']),
  style: PropTypes.object,
  variant: PropTypes.oneOf(['contained', 'outlined'])
};

Label.defaultProps = {
  style: {},
  color: colors.grey[600],
  variant: 'contained',
  shape: 'square',
  enableClick: false
};

export default Label;
