// eslint-disable-next-line react-hooks/exhaustive-deps
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { getToken } from 'services/auth';
import UserService from 'services/user';
import SubscriptionService from 'services/subscription';
import ClientService from 'services/client';
import NotificationService from 'services/notification';

import {
  login,
  logout,
  subscriptionMessage,
  showSubscriptionMessage,
  clientIdLastAccessed,
  notificationInbox,
  notificationInsight,
  productTypes
} from 'actions';
import ProductTypeService from 'services/productTypeService';

function AuthGuard(roles) {
  const session = useSelector(state => state.session);
  const client = useSelector(state => state.client);
  const dispatch = useDispatch();
  const token = getToken();
  const history = useHistory();

  useEffect(() => {
    let loaded = true;

    const handlerAuth = async () => {
      if (token !== undefined && (!session.loggedIn || session.user.id <= 0)) {
        const user = await UserService.getUserDetail();
        if (user != null) {
          user.partner = await UserService.getPartnerCode();
        }
        dispatch(login(user));

        if (user && user.emails && !user.emails[0].confirmed) {
          history.push('/auth/email-confirmation');
          return;
        }

        let recentClientId = 0;
        if (client.clientIdLastAccessed === 0) {
          recentClientId = await ClientService.getRecentId();
          client.clientIdLastAccessed = recentClientId;
          dispatch(clientIdLastAccessed(recentClientId));
        }

        const subMessages = await SubscriptionService.getSubscriptionMessages();
        if (subMessages) {
          dispatch(subscriptionMessage(subMessages));
          dispatch(showSubscriptionMessage(true));
        }

        dispatchNotificationInbox();
        dispatchNotificationInsight(recentClientId);
        //dispatchProductTypes();

        return;
      } else {
        if (
          session.user &&
          session.user.emails &&
          !session.user.emails[0].confirmed
        ) {
          history.push('/auth/email-confirmation');
          return;
        }
      }

      if (!session.loggedIn || session.user.id <= 0) {
        dispatch(logout());
        dispatch(clientIdLastAccessed(0));
        dispatch(subscriptionMessage(null));
        dispatch(showSubscriptionMessage(false));
        history.push('/auth/login');
        return;
      }

      if (!roles.includes(session.role)) {
        history.push('/errors/error-401');
        return;
      }
    };

    const dispatchNotificationInbox = async () => {
      const notificationInboxCount = await NotificationService.inboxCount();
      dispatch(notificationInbox(notificationInboxCount));
    };

    const dispatchNotificationInsight = async clientId => {
      const notificationInsightCount = await NotificationService.insightCount(
        clientId
      );
      dispatch(notificationInsight(notificationInsightCount));
    };

    const dispatchProductTypes = async () => {
      const productTypeList = await ProductTypeService.getProductTypes();
      let lstProducts = [];
      if (productTypeList) {
        productTypeList.forEach(p => {
          lstProducts.push({
            name: p.key,
            productType: p.value,
            isActive: true
          });
        });
      }
      dispatch(productTypes(lstProducts));
    };

    if (loaded) {
      handlerAuth();
    }

    return () => {
      loaded = false;
    };
  }, [dispatch, session, history, token]);
}

AuthGuard.propTypes = {
  roles: PropTypes.array.isRequired
};

export default AuthGuard;
