import React, { Suspense, useEffect, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import SubscriptionTopBar from '../../components/Message/SubscriptionTopBar';

import { useSelector } from 'react-redux';

import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      zIndex: 0
    }
  },
  container: {
    display: 'flex',
    flex: '1 1 auto'
  },
  navBar: {
    width: 300,
    zIndex: 3,
    flex: '0 0 auto',
    borderRight: 'solid 2px #eeeeee'
  },
  content: {
    //paddingTop: 20,
    width: '100%'
  },
  contentLimited: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 1090
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  }
}));

const MasterWithNav = ({ route }) => {
  const classes = useStyles();
  const session = useSelector(state => state.session);

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  useEffect(() => { }, [
    session.showSubscriptionMessage,
    session.showSubscriptionMessage
  ]);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          {session.subscriptionMessage && session.showSubscriptionMessage && (
            <SubscriptionTopBar
              subscriptionMessage={session.subscriptionMessage}
            />
          )}

          <div className={classes.contentLimited}>
            <Suspense fallback={<LinearProgress />}>
              <TopBar
                className={classes.topBar}
                onOpenNavBarMobile={handleNavBarMobileOpen}
                showSubscriptionMessage={session.showSubscriptionMessage}
              />
              {renderRoutes(route.routes)}
            </Suspense>
          </div>
        </main>
      </div>
    </div>
  );
};

MasterWithNav.propTypes = {
  route: PropTypes.object
};

export default MasterWithNav;
