import api from './api';

export class SubscriptionService {
  static subscribe = async order => {
    await api
      .post('/api/v1/subscription/subscribe', order)
      .then(async result => {
        return result;
      })
      .catch(() => {
        throw new Error('Dados incorretos');
      });
  };

  static saveCreditCard = async creditCard => {
    const response = await api.post(
      '/api/v1/subscription/credit-card',
      creditCard
    );

    if (response.data === null) {
      throw new Error('Dados incorretos');
    }

    return response.data.result;
  };

  static getCurrentSubscription = async () => {
    try {
      const response = await api.get('/api/v1/subscription/plans');

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getSubscription = async () => {
    try {
      const response = await api.get('/api/v1/subscription');

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getCreditCard = async () => {
    try {
      const response = await api.get('/api/v1/subscription/credit-card');

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static cancelSubscriptions = async () => {
    try {
      const response = await api.post('/api/v1/subscription/cancel');

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getPaymentHistory = async () => {
    try {
      const response = await api.get('/api/v1/subscription/payment-history');

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getSubscriptionTypes = async partnerCode => {
    try {
      let paramPartnerCode = '';
      if (partnerCode && partnerCode !== undefined && partnerCode !== '') {
        paramPartnerCode = `?partnerCode=${partnerCode}`;
      }

      const response = await api.get(
        `/api/v1/subscription/types${paramPartnerCode}`
      );

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getSubscriptionMessages = async () => {
    try {
      const response = await api.get('/api/v1/subscription/messages');

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data;
    } catch (ex) {
      return ex;
    }
  };
}

export default SubscriptionService;
