import axios from 'axios';
import api from './api';
import { getTokenClient } from './auth';
import usePhoneHelper from '../utils/helper';

export class UserService {
  static register = async user => {
    const apiRegister = axios.create({
      // eslint-disable-next-line no-undef
      baseURL: process.env.REACT_APP_MOKSHA_API_BASEURL
    });
    let accessToken = await getTokenClient();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken
    };

    await apiRegister.post('/api/v1/user', user, {
      headers: headers
    });
  };

  static update = async user => {
    try {
      await api.put('/api/v1/user', user);
    } catch (ex) {
      return ex;
    }
  };

  static emailConfirmation = async (token, email) => {
    try {
      const response = await api.post('/api/v1/user/confirmation-email', null, {
        params: {
          token,
          email
        }
      });

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getUserDetail = async () => {
    try {
      const response = await api.get('/api/v1/user/detail');
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static changePassword = async (password, passwordNew) => {
    try {
      const response = await api.post('/api/v1/user/change-password', {
        password,
        passwordNew
      });
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static resendEmailCode = async email => {
    try {
      await api.post(`/api/v1/user/resend-email-validation?email=${email}`);
    } catch (ex) {
      return ex;
    }
  };

  static forgotPassword = async email => {
    try {
      const response = await api.post(
        `/api/v1/user/forgot-password?email=${email}`
      );
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static resetPasswordWithEmail = async (email, resetPasswordCriteria) => {
    try {
      const response = await api.post(
        `/api/v1/user/reset-password/${email}`,
        resetPasswordCriteria
      );
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getSocialMedia = async () => {
    try {
      const response = await api.get('/api/v1/user/social-media');
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static saveSocialMedia = async (id, userName, socialMediaTypeId) => {
    try {
      let response = await api.post('/api/v1/user/social-media', [
        {
          id: id,
          userName: userName,
          socialMediaTypeId: socialMediaTypeId
        }
      ]);

      if (response.data === null) {
        throw new Error(response);
      }

      return true;
    } catch (ex) {
      //console.log(ex);
      return false;
    }
  };

  static deleteSocialMedia = async id => {
    try {
      let response = await api.delete(`/api/v1/user/social-media/${id}`);

      if (response.data === null) {
        throw new Error(response);
      }

      return true;
    } catch (ex) {
      //console.log(ex);
      return false;
    }
  };

  static existsByUsername = async username => {
    try {
      const response = await api.get(
        `/api/v1/user/social-media/exists/${username}`
      );
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static getPartnerCode = async () => {
    try {
      const response = await api.get('/api/v1/user/partner-code');
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static loginAttempt = async () => {
    try {
      const response = await api.get('/api/v1/user/social-media/attempt/count');
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static mapping = user => {
    let phoneHelper = usePhoneHelper();

    let obj = {
      firstName: user.firstName,
      lastName: user.lastName,
      //emails: user.emails,
      password: user.password ? user.password : undefined,
      passwordNew: user.passwordNew ? user.passwordNew : undefined,
      phones: user.phones
        ?.filter(a => !!a.numberFull)
        ?.map(phone => {
          return {
            ...phone,
            ...phoneHelper.parse(phone.numberFull)
          };
        }),
      phonesDelete:
        user.phonesDelete && user.phonesDelete.map(phone => phone.id),
      document: user.documents[0],
      addresses: user.addresses?.filter(
        a => a.placeGatewayId && a.placeGatewayId !== ''
      ),
      addressesDelete: user.addresses
        ?.filter(
          a => a.id > 0 && (!a.placeGatewayId || a.placeGatewayId === '')
        )
        ?.map(address => address.id),
      birthDate:
        user.birthDate === '0001-01-01T00:00:00' || user.birthDate === ''
          ? ''
          : user.birthDate
    };

    return obj;
  };
}

export default UserService;
