const white = '#ffffff';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#ff2d64',
    main: '#ff2d64',
    light: '#f87496'
  },
  secondary: {
    contrastText: white,
    dark: '#1fbbd4',
    main: '#2197c2',
    light: '#1fbbd4'
  },
  tertiary: {
    contrastText: white,
    dark: '#663ACA',
    main: '#663ACA',
    light: '#A183E6'
  },
  quartenary: {
    contrastText: white,
    dark: '#F4CB0B',
    main: '#F4CB0B',
    light: '#F4CB0B'
  },
  graphicColor: {
    primary: '#ff2d64',
    secondary: '#2197c2',
    tertiary: '#b6d178',
    quartenary: '#663aca',
    quinary: '#cccccc',
    senary: '#005f82',
    setenary: '#310a89',
    octonary: '#8e1434',
    nonary: '#688526',
    decenary: '#575757'
  },
  success: {
    contrastText: white,
    dark: '#B6D178',
    main: '#B6D178',
    light: '#D7E9AF'
  },
  error: {
    contrastText: white,
    dark: '#cd0e0e',
    main: '#ff0000',
    light: '#fb4b4b'
  },
  text: {
    primary: '#444444',
    secondary: '#888888',
    tertiary: '#fffefe',
    link: '#ff2d64'
  },
  link: '#888888',
  icon: '#444444',
  background: {
    default: '#ffffff',
    paper: white
  },
  gray: '#eeeeee',
  disabled: '#bdbdbd',
  darkgrey: '#cccccc',
  action: {
    main: '#959595'
  }
};
