/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, IconButton, Toolbar, Hidden, colors } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  boxPartner: {
    margin: 0,
    position: 'absolute',
    right: 25,
    top: 2,
    zIndex: 2,

    [theme.breakpoints.down('xs')]: {
      marginTop: '4px',
      position: 'relative',
      right: 0,
      top: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '4px',
      position: 'relative',
      right: 0,
      top: 0
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '4px',
      position: 'relative',
      right: 0,
      top: 0
    }
  },
  boxPartnerExtra: {
    margin: 0,
    position: 'absolute',
    right: 25,
    top: 32,
    zIndex: 2,

    [theme.breakpoints.down('xs')]: {
      marginTop: '4px',
      position: 'relative',
      right: 0,
      top: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '4px',
      position: 'relative',
      right: 0,
      top: 0
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '4px',
      position: 'relative',
      right: 0,
      top: 0
    }
  },
  logoPartner: {
    height: '60px',
    [theme.breakpoints.down('xs')]: {
      height: '40px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '40px'
    },
    [theme.breakpoints.down('md')]: {
      height: '40px'
    }
  },
  logoPartnerUNAV: {
    height: '60px',
    [theme.breakpoints.down('xs')]: {
      height: '40px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '40px'
    },
    [theme.breakpoints.down('md')]: {
      height: '40px'
    }
  },
  logoPartnerCAGENTE: {
    height: '40px',
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      height: '25px',
      marginTop: -4
    },
    [theme.breakpoints.down('sm')]: {
      height: '25px',
      marginTop: -4
    },
    [theme.breakpoints.down('md')]: {
      height: '25px',
      marginTop: -4
    }
  },
  logoPartnerMAISAGENTE: {
    height: '40px',
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      height: '25px',
      marginTop: -4
    },
    [theme.breakpoints.down('sm')]: {
      height: '25px',
      marginTop: -4
    },
    [theme.breakpoints.down('md')]: {
      height: '25px',
      marginTop: -4
    }
  }
}));

const TopBar = ({
  onOpenNavBarMobile,
  showSubscriptionMessage,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const session = useSelector(state => state.session);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="inherit">
      <Toolbar
        style={{
          minHeight: showSubscriptionMessage === true ? '16px' : '46px'
        }}>
        <Hidden lgUp>
          <RouterLink to="/">
            <img alt="Logo" src="/images/logos/logo-red.svg" width="134" />
          </RouterLink>
        </Hidden>
        <div className={classes.flexGrow} />
        {session.user?.partner?.partner?.logoUrl && (
          <>
            <div
              className={
                showSubscriptionMessage === true
                  ? classes.boxPartner
                  : classes.boxPartnerExtra
              }>
              <img
                alt="Logo"
                src={session.user?.partner?.partner?.logoUrl}
                className={
                  session.user?.partner?.code
                    ? session.user?.partner?.code === 'UNAV21'
                      ? classes.logoPartnerUNAV
                      : session.user?.partner?.code === 'CAGENTE21'
                      ? classes.logoPartnerCAGENTE
                      : classes.logoPartnerMAISAGENTE
                    : classes.logoPartner
                }
              />
            </div>
            <div className={classes.flexGrow} />
          </>
        )}
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  showSubscriptionMessage: PropTypes.bool
};

export default TopBar;
