import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import Markdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { showSubscriptionMessage } from 'actions';

const useStyles = makeStyles(theme => ({
  root: {},
  messageStatus: {
    '& a': {
      textDecoration: 'underline',
      color: theme.palette.text.tertiary
    }
  },
  text: {
    color: theme.palette.text.tertiary,
    padding: '5px'
  }
}));

const SubscriptionTopBar = ({ subscriptionMessage, ...rest }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        backgroundColor: subscriptionMessage.backgroundColor
      }}
      className={classes.messageStatus}>
      {subscriptionMessage.closable && <Grid item sm={1} />}

      <Grid item sm={10} xs={11}>
        <Typography
          component="h6"
          variant="h6"
          align="center"
          className={classes.text}>
          <Markdown source={subscriptionMessage.message} />
        </Typography>
      </Grid>

      {subscriptionMessage.closable && (
        <Grid item sm={1} xs={1}>
          <Typography
            component="h6"
            variant="h6"
            align="right"
            className={classes.text}
            style={{
              cursor: 'pointer'
            }}
            onClick={() => dispatch(showSubscriptionMessage(false))}>
            x
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

SubscriptionTopBar.propTypes = {
  subscriptionMessage: PropTypes.object.isRequired
};

SubscriptionTopBar.defaultProps = {
  subscriptionMessage: PropTypes.obj
};

export default SubscriptionTopBar;
