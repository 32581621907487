export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_SUBSCRIPTION = 'SESSION_SUBSCRIPTION';
export const SESSION_SUBSCRIPTION_TYPES = 'SESSION_SUBSCRIPTION_TYPES';
export const SESSION_SUBSCRIPTION_MSG = 'SESSION_SUBSCRIPTION_MSG';
export const SESSION_SHOW_SUBSCRIPTION_MSG = 'SESSION_SHOW_SUBSCRIPTION_MSG';
export const SESSION_PRODUCT_TYPES = 'SESSION_PRODUCT_TYPES';

export const login = user => dispatch =>
  dispatch({
    type: SESSION_LOGIN,
    user: user
  });

export const logout = () => dispatch =>
  dispatch({
    type: SESSION_LOGOUT
  });

export const subscription = subscription => dispatch =>
  dispatch({
    type: SESSION_SUBSCRIPTION,
    subscription: subscription
  });

export const subscriptionTypes = subscriptionTypes => dispatch =>
  dispatch({
    type: SESSION_SUBSCRIPTION_TYPES,
    subscriptionTypes: subscriptionTypes
  });

export const subscriptionMessage = subscriptionMessage => dispatch =>
  dispatch({
    type: SESSION_SUBSCRIPTION_MSG,
    subscriptionMessage: subscriptionMessage
  });

export const showSubscriptionMessage = showSubscriptionMessage => dispatch =>
  dispatch({
    type: SESSION_SHOW_SUBSCRIPTION_MSG,
    showSubscriptionMessage: showSubscriptionMessage
  });

export const productTypes = productTypes => dispatch =>
  dispatch({
    type: SESSION_PRODUCT_TYPES,
    productTypes: productTypes
  });
