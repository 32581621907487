import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ClientService from 'services/client';
import usePhoneHelper from '../../utils/helper';

function ClientDisplay() {
  const [clientDisplay, setClientDisplay] = useState('');
  const session = useSelector(state => state.client);
  const phoneHelper = usePhoneHelper();

  useEffect(() => {
    getClientRecent();
  }, [session.clientIdLastAccessed, clientDisplay]);

  const getClientRecent = async () => {
    if (session.clientIdLastAccessed > 0) {
      await ClientService.getClientDetail(session.clientIdLastAccessed).then(
        detail => {
          let firstName = detail.firstName;
          let lastName = detail.lastName ? detail.lastName : '';
          if (lastName != '' && lastName != undefined) {
            let lastNameSplit = lastName.split(' ');
            lastName = lastNameSplit[lastNameSplit.length - 1];
          }
          setClientDisplay(firstName + ' ' + lastName);
        }
      );
    }
  };

  return (
    <>
      {clientDisplay ? phoneHelper.textLimiter(clientDisplay, 15) : 'Cliente'}
    </>
  );
}

export default ClientDisplay;
