import * as actionTypes from 'actions';
const initialState = {
  subscription: null
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBSCRIPTION_PLAN: {
      return {
        ...state,
        plan: action.plan
      };
    }

    default: {
      return state;
    }
  }
};

export default subscriptionReducer;
