import api from './api';

export default class NotificationService {
  static get = async (pageNumber, pageSize) => {
    try {
      const response = await api.get('api/v1/notification', {
        params: {
          pageNumber,
          pageSize
        }
      });

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getByClientId = async (clientId, pageNumber, pageSize) => {
    try {
      const response = await api.get(
        `api/v1/notification/insight/client/${clientId}`,
        {
          params: {
            pageNumber,
            pageSize
          }
        }
      );

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static getByAllClient = async (pageNumber, pageSize, topNotification) => {
    try {
      const response = await api.get('api/v1/notification/insight/grouped', {
        params: {
          pageNumber,
          pageSize,
          topNotification
        }
      });

      if (response.data === null) {
        throw new Error(response);
      }

      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };

  static delete = async id => {
    try {
      let response = await api.delete(`/api/v1/notification/${id}`);
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static favoriteByClient = async clientId => {
    try {
      let response = await api.put(
        `/api/v1/notification/insight/client/${clientId}/favorite`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static muteByClient = async clientId => {
    try {
      let response = await api.put(
        `/api/v1/notification/insight/client/${clientId}/mute`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static markAsRead = async notificationId => {
    try {
      let response = await api.put(
        `/api/v1/notification/${notificationId}/read`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static favorite = async notificationId => {
    try {
      let response = await api.put(
        `/api/v1/notification/${notificationId}/favorite`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static inboxCount = async () => {
    try {
      let response = await api.get('/api/v1/notification/inbox/count');
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };

  static insightCount = async clientId => {
    try {
      let response = await api.get(
        `/api/v1/notification/insight/client/${clientId}/count`
      );
      if (response.data === null) {
        throw new Error(response);
      }
      return response.data;
    } catch (ex) {
      return ex;
    }
  };
}
