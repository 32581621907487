export default {
  contained: {
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    },
    disableRipple: true
  },
  label: {
    textTransform: 'none'
  },
  text: {
    padding: '2px 16px'
  }
};
