import * as actionTypes from 'actions';
const initialState = {
  clientIdLastAccessed: 0,
  clientName: ''
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLIENT_LAST_ACCESSED: {
      return {
        ...state,
        clientIdLastAccessed: action.clientIdLastAccessed
      };
    }
    case actionTypes.CLIENT_NAME: {
      return {
        ...state,
        clientName: action.clientName
      };
    }

    default: {
      return state;
    }
  }
};

export default clientReducer;
