import { combineReducers } from 'redux';

import userReducer from './userReducer';
import clientReducer from './clientReducer';
import notificationReducer from './notificationReducer';
import subscriptionReducer from './subscriptionReducer';

const rootReducer = combineReducers({
  session: userReducer,
  client: clientReducer,
  notification: notificationReducer,
  subscription: subscriptionReducer
});

export default rootReducer;
