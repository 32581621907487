export default {
  root: {
    marginBottom: '15px'
  },
  input: {
    height: 13,
    '&::placeholder': {
      opacity: 1,
      color: 'rgba(0, 0, 0, 0.03)'
    }
  }
};
