import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Hidden } from '@material-ui/core';

import TopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
    paddingTop: 40,
    paddingLeft: 25
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 300,
    minWidth: 300,
    flex: '0 0 auto',
    borderRight: 'solid 2px #eeeeee'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  },
  innerContent: {
    paddingLeft: 40,
    paddingTop: 60
  }
}));

const Master = ({ route }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Hidden xsDown>
          <div className={classes.navBar}>
            <TopBar className={classes.topBar} />
          </div>
        </Hidden>

        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            <div className={classes.innerContent}>
              {renderRoutes(route.routes)}
            </div>
          </Suspense>
        </main>
      </div>
    </div>
  );
};

Master.propTypes = {
  route: PropTypes.object
};

export default Master;
