import api from './api';

export default class ProductTypeService {
  static getProductTypes = async () => {
    try {
      const response = await api.get('api/v1/producttype');
      if (response.data === null) {
        throw new Error(response);
      }

      return response.data.result;
    } catch (ex) {
      return ex;
    }
  };
}
