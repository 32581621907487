/* eslint-disable react/no-multi-comp */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Grid, SvgIcon } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import { ReactComponent as ExitToAppIcon } from '../../assets/menuIcons/sair.svg';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  buttonLeaf: {
    width: '100%'
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const Navigation = props => {
  const { title, pages, className, component: Component, ...rest } = props;

  const classes = useStyles();
  const depth = 0;

  let paddingLeft = 8;
  let fontSize = 17;
  let paddingTop = 8;
  let paddingBottom = 8;

  if (depth > 0) {
    paddingLeft = 16;
    fontSize = 14;
    paddingTop = 0;
    paddingBottom = 0;
  }

  const style = {
    paddingLeft,
    fontSize,
    paddingTop,
    paddingBottom,
    color: ''
  };

  const styleIcon = {
    color: '',
    fontSize: 25,
    marginRight: 5
  };

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      <div>
        <ListItem
          {...rest}
          className={clsx(classes.itemLeaf, className)}
          disableGutters>
          <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={CustomRouterLink}
            exact
            style={style}
            to="/auth/logout"
            disableRipple={true}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between">
              <Grid item>Sair</Grid>
              <Grid>
                <SvgIcon className={classes.icon} style={styleIcon}>
                  <ExitToAppIcon />
                </SvgIcon>
              </Grid>
            </Grid>
          </Button>
        </ListItem>
      </div>
    </Component>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string
};

Navigation.defaultProps = {
  component: 'nav'
};

export default Navigation;
