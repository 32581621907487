import * as actionTypes from 'actions';
const initialState = {
  inboxCount: 0,
  insightCount: 0
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_INBOX: {
      return {
        ...state,
        inboxCount: action.inboxCount
      };
    }
    case actionTypes.NOTIFICATION_INSIGHT: {
      return {
        ...state,
        insightCount: action.insightCount
      };
    }

    default: {
      return state;
    }
  }
};

export default notificationReducer;
