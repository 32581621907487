export default {
  gutterBottom: {
    marginBottom: 8
  },

  caption: {
    color: '#000000'
  }

};
