/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */

import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import ErrorLayout from './layouts/Error';
import MasterWithNavLayout from './layouts/MasterWithNav';
import MasterLayout from './layouts/Master';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/profile" />
  },
  {
    path: '/auth',
    component: MasterLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Auth/Login'))
      },
      {
        path: '/auth/email-confirmation',
        exact: true,
        component: lazy(() => import('views/Auth/EmailConfirmation'))
      },
      // {
      //   path: '/auth/register',
      //   exact: true,
      //   component: lazy(() => import('views/Auth/Register'))
      // },
      {
        path: '/auth/logout',
        exact: true,
        component: lazy(() => import('views/Auth/Logout'))
      },
      {
        path: '/auth/forgot-password',
        exact: true,
        component: lazy(() => import('views/Auth/ForgotPassword'))
      },
      {
        path: '/auth/redirect',
        exact: false,
        component: lazy(() => import('views/Auth/Redirect'))
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: MasterWithNavLayout,
    routes: [
      {
        path: '/profile',
        exact: true,
        component: lazy(() => import('views/MyProfile'))
      },
      {
        path: '/profile/:tab',
        exact: true,
        component: lazy(() => import('views/MyProfile'))
      },
      {
        path: '/client',
        exact: true,
        component: lazy(() => import('views/Client'))
      },
      {
        path: '/client/:tab',
        exact: true,
        component: lazy(() => import('views/Client'))
      },
      {
        path: '/client/:id/:tab',
        exact: true,
        component: lazy(() => import('views/Client'))
      },
      {
        path: '/contacts',
        exact: true,
        component: lazy(() => import('views/Contacts'))
      },
      {
        path: '/import',
        exact: true,
        component: lazy(() => import('views/Import'))
      },
      {
        path: '/import/:tab',
        exact: true,
        component: lazy(() => import('views/Import'))
      },
      {
        path: '/support',
        exact: true,
        component: lazy(() => import('views/Support'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
