import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Paper } from '@material-ui/core';
import { Hidden } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import AuthGuard from 'components/AuthGuard';
import Navigation from 'components/Navigation/Navigation';
import NavigationBlock from 'components/Navigation/NavigationBlock';
import navigationConfig from './navigationConfig';
import SubscriptionService from 'services/subscription';
import { Link as RouterLink } from 'react-router-dom';
import { subscriptionPlan } from 'actions';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    borderRight: 1,
    borderColor: '#eeeeee'
  },
  content: {
    padding: theme.spacing(2),
    fontFamily: 'Montserrat SemiBold'
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
    paddingLeft: theme.spacing(1),
    paddingTop: 15
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRith: theme.spacing(2)
  }
}));

const NavBar = ({ openMobile, onMobileClose, className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);

  const router = useRouter();
  const history = useHistory();
  const [isSubscriptionBlock, setIsSubscriptionBlock] = useState(false);

  AuthGuard(['Administrator', 'Agent']);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    const getSubscription = async () => {
      const result = await SubscriptionService.getCurrentSubscription();
      if (!result) {
        setIsSubscriptionBlock(true);
        history.push('/profile/subscription');
      } else {
        setIsSubscriptionBlock(false);
        dispatch(subscriptionPlan(result));
      }
    };

    getSubscription();
  }, [router.location.pathname]);

  if (!session.loggedIn) {
    return null;
  }
  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile} />
      <nav className={classes.navigation}>
        <div className={classes.topBar}>
          <RouterLink to="/">
            <img alt="Logo" src="/images/logos/logo-red.svg" width="134" />
          </RouterLink>
        </div>
        {navigationConfig.map((list, i) =>
          isSubscriptionBlock ? (
            <NavigationBlock
              component="div"
              key={list.title + i}
              pages={list.pages}
              title={list.title}
            />
          ) : (
            <Navigation
              component="div"
              key={list.title + i}
              pages={list.pages}
              title={list.title}
            />
          )
        )}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square>
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
