import * as actionTypes from 'actions';

const initialState = {
  loggedIn: false,
  role: 'Guest',
  user: {
    id: 0
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...initialState,
        loggedIn: true,
        role: 'Administrator',
        user: action.user
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        role: 'Guest',
        user: {
          id: 0
        }
      };
    }

    case actionTypes.SESSION_SUBSCRIPTION: {
      return {
        ...state,
        subscription: action.subscription
      };
    }

    case actionTypes.SESSION_SUBSCRIPTION_TYPES: {
      return {
        ...state,
        subscriptionTypes: action.subscriptionTypes
      };
    }

    case actionTypes.SESSION_SUBSCRIPTION_MSG: {
      return {
        ...state,
        subscriptionMessage: action.subscriptionMessage
      };
    }

    case actionTypes.SESSION_SHOW_SUBSCRIPTION_MSG: {
      return {
        ...state,
        showSubscriptionMessage: action.showSubscriptionMessage
      };
    }

    case actionTypes.SESSION_PRODUCT_TYPES: {
      return {
        ...state,
        productTypes: action.productTypes
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
