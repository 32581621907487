/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

//import { colors } from '@material-ui/core';

//import Label from 'components/Label';
import React from 'react';
import { ReactComponent as AddCircleIcon } from '../../assets/menuIcons/novocliente.svg';
import { ReactComponent as HomeIcon } from '../../assets/menuIcons/minhaconta.svg';
import { ReactComponent as PersonIcon } from '../../assets/menuIcons/cliente.svg';
import { ReactComponent as ContactsIcon } from '../../assets/menuIcons/contatos.svg';
import { ReactComponent as ExitToAppIcon } from '../../assets/menuIcons/sair.svg';
import { ReactComponent as SupportIcon } from '../../assets/menuIcons/suporte.svg';
import { ReactComponent as ImportIcon } from '../../assets/menuIcons/importicon.svg';
import NotificationCount from '../../components/Count/NotificationCount';
import ClientDisplay from '../../components/Display/ClientDisplay';

export default [
  {
    pages: [
      {
        title: 'Novo cliente',
        href: '/client/create',
        icon: AddCircleIcon,
        large: true,
        color: '#ff2d64'
      },
      {
        title: 'Minha conta',
        href: '/profile',
        icon: HomeIcon,
        iconHref: '/profile',
        children: [
          {
            title: 'Home',
            href: '/profile/home'
          },
          {
            title: 'Inbox',
            href: '/profile/inbox',
            label: () => <NotificationCount notificationType="inbox" />
          },
          {
            title: 'Social',
            href: '/profile/social'
          },
          {
            title: 'Meus dados',
            href: '/profile/detail'
          },
          {
            title: 'Assinatura',
            href: '/profile/subscription'
          },
          {
            title: '',
            href: '',
            divider: true
          }
        ]
      },
      {
        title: <ClientDisplay />,
        href: '/client',
        titleDescription: true,
        icon: PersonIcon,
        children: [
          {
            title: 'Perfil',
            href: '/client/profile'
          },
          {
            title: 'Timeline',
            href: '/client/timeline'
          },
          {
            title: 'Reservas',
            href: '/client/booking'
          },
          // {
          //   title: 'Insights',
          //   href: '/client/insights',
          //   label: () => <NotificationCount notificationType="insight" />
          // },
          {
            title: 'Passaporte',
            href: '/client/passport'
          },
          {
            title: 'Covid',
            href: '/client/covid'
          },
          {
            title: 'Notas',
            href: '/client/notes'
          },
          {
            title: 'Dados de contato',
            href: '/client/info'
          },
          {
            title: '',
            href: '',
            divider: true
          }
          /*
          {
            title: 'Social',
            href: '/client/social'
          },
          */
        ]
      },

      {
        title: 'Contatos',
        href: '/contacts',
        icon: ContactsIcon
      },
      {
        title: 'Importação',
        href: '/import',
        icon: ImportIcon,
        children: [
          {
            title: 'Reservas',
            href: '/import/booking'
          },
          {
            title: 'Histórico',
            href: '/import/history'
          }
        ]
      },
      {
        title: 'Suporte',
        href: '/support',
        icon: SupportIcon,
        children: [
          {
            href: '/Support',
            title: 'Suporte'
          },
          {
            href: '#',
            title: (
              <p
                style={{
                  color: '#444444',
                  fontSize: '14px',
                  fontFamily: 'Montserrat SemiBold, Arial',
                  cursor: 'pointer'
                }}
                onClick={() =>
                  window.open('https://tutorial.moksha.cx/', '_blank')
                }>
                Tutorial
              </p>
            )
          }
        ]
      },

      // {
      //   title: 'Settings',
      //   href: '/settings',
      //   icon: SettingsIcon,
      //   children: [
      //     {
      //       title: 'General',
      //       href: '/settings/general'
      //     },
      //     {
      //       title: 'Subscription',
      //       href: '/settings/subscription'
      //     },
      //     {
      //       title: 'Notifications',
      //       href: '/settings/notifications'
      //     },
      //     {
      //       title: 'Security',
      //       href: '/settings/security'
      //     }
      //   ]
      // },
      {
        title: 'Sair',
        href: '/auth/logout',
        icon: ExitToAppIcon
      }
    ]
  }
];
