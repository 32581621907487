/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { useHistory } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse, Grid, SvgIcon } from '@material-ui/core';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block'
  },
  itemLeaf: {
    display: 'flex'
  },
  button: {
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  icon: {
    color: theme.palette.icon,
    marginTop: 8
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: '6px',
    width: '20px',
    height: '20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderRadius: '10px',
    fontSize: '11px'
  },
  active: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));

const NavigationListItem = ({
  title,
  subtitle: Subtitle,
  href,
  depth,
  children,
  icon: Icon,
  iconHref,
  className,
  open: openProp,
  label: Label,
  large,
  textColor,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(open => !open);
  };

  let paddingLeft = 8;
  let fontSize = 17;
  let paddingTop = 8;
  let paddingBottom = 8;

  if (depth > 0) {
    paddingLeft = 16;
    fontSize = 14;
    paddingTop = 0;
    paddingBottom = 0;
  }

  const style = {
    paddingLeft,
    fontSize,
    paddingTop,
    paddingBottom,
    color: textColor || ''
  };

  const styleIcon = {
    color: textColor || '',
    fontSize: Icon && large ? 35 : 25,
    marginRight: Icon && large ? 0 : 5
  };

  const handlerIconClick = () => {
    if (iconHref) {
      history.push(iconHref);
    }
  };

  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.item, className)}
        disableGutters>
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={style}
          disableRipple={true}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Grid item>{title}</Grid>
            {Icon && (
              <Grid>
                <SvgIcon
                  className={classes.icon}
                  style={styleIcon}
                  onClick={handlerIconClick}>
                  <Icon />
                </SvgIcon>
              </Grid>
            )}
          </Grid>
        </Button>
        {Subtitle && <Subtitle />}
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.itemLeaf, className)}
        disableGutters>
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={CustomRouterLink}
          exact
          style={style}
          to={ReplaceUrl(href)}
          disableRipple={true}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Grid item>{title}</Grid>

            {Label && (
              // <span className={classes.label}>
              <Label />
              // </span>
            )}
            {Icon && (
              <Grid>
                <SvgIcon className={classes.icon} style={styleIcon}>
                  <Icon />
                </SvgIcon>
              </Grid>
            )}
          </Grid>
        </Button>
      </ListItem>
    );
  }
};

const ReplaceUrl = href => {
  let url = href;

  // const session = useSelector(state => state.client);
  // if (href.includes(':clientId')) {
  //   if (href) {
  //     url = href.replace(/:clientId/, session.clientIdLastAccessed);
  //   }
  // }
  return url;
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  divider: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.any,
  iconHref: PropTypes.string,
  label: PropTypes.any,
  large: PropTypes.bool,
  open: PropTypes.bool,

  textColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.any
};

NavigationListItem.defaultProps = {
  depth: 0,
  open: false
};

export default NavigationListItem;
