export const NOTIFICATION_INBOX = 'NOTIFICATION_INBOX';
export const NOTIFICATION_INSIGHT = 'NOTIFICATION_INSIGHT';

export const notificationInbox = count => dispatch =>
  dispatch({
    type: NOTIFICATION_INBOX,
    inboxCount: count
  });

export const notificationInsight = count => dispatch =>
  dispatch({
    type: NOTIFICATION_INSIGHT,
    insightCount: count
  });
